import React, { useState } from "react";
import "./Login.css";
import { signInWithPopup } from "firebase/auth";
import db, { auth, googleProvider } from "../Firebase";
import { useStateValue } from "../StateProvider";
import { actionTypes } from "../reducer";
import { Button } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  setDoc,
} from "firebase/firestore";

function Login() {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useStateValue();

  const [showMicrosoftAlert, setShowMicrosoftAlert] = useState(false);

  const [showUserNotRegisteredError, setShowUserNotRegisteredError] =
    useState(false);

  const [showErrorLogin, setShowErrorLogin] = useState(false);
  const signInWithGoogle = () => {
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        console.log("user: ", result.user);

        const allowedUsersCollectionRef = collection(db, "allowed_users");
        const allowedUsersQuery = query(
          allowedUsersCollectionRef,
          where("email", "==", result.user.email)
        );
        const allowedUsersDocs = await getDocs(allowedUsersQuery);

        const registeredUserDoc = doc(db, "registered_users", result.user.uid);

        if (!registeredUserDoc.exists && allowedUsersDocs.empty) {
          setShowUserNotRegisteredError(true);
        } else {
          const registeredUser = {
            email: result.user.email,
            displayName: result.user.displayName,
            photoURL: result.user.photoURL,
          };

          const registeredUserDocRef = doc(
            db,
            "registered_users",
            result.user.uid
          );

          await setDoc(registeredUserDocRef, registeredUser);

          setShowErrorLogin(false);
          setShowMicrosoftAlert(false);
          setShowUserNotRegisteredError(false);

          dispatch({
            type: actionTypes.SET_USER,
            user: result.user,
          });
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };
  const signInWithMicrosoft = () => {
    setShowMicrosoftAlert(true);
  };

  const handleMicrosoftAlertClose = () => {
    setShowMicrosoftAlert(false);
  };

  const handleErrorLoginClose = () => {
    setShowErrorLogin(false);
  };

  const handleUserNotRegisteredErrorError = () => {
    setShowUserNotRegisteredError(false);
  };

  return (
    <div className="login">
      <div className="login__logo">
        <img src="/Full_logo-color_on_white.png" alt="Vicunous logo" />
      </div>

      <div className="login__signin">
        <h1>Log in with</h1>
        <div className="login__button">
          <Button
            variant="text"
            startIcon={
              <img
                src="/google_logo.svg"
                alt="Google Sign In Logo"
                height="30px"
              />
            }
            onClick={signInWithGoogle}
          >
            Google
          </Button>
        </div>

        <div className="login__button">
          <Button
            variant="text"
            startIcon={
              <img
                src="/microsoft_logo.svg"
                alt="Microsoft Sign In Logo"
                height="30px"
              />
            }
            onClick={signInWithMicrosoft}
          >
            Microsoft
          </Button>
        </div>
      </div>

      <div className="login__error">
        {showUserNotRegisteredError && (
          <Alert
            severity="warning"
            open={showUserNotRegisteredError}
            onClose={handleUserNotRegisteredErrorError}
          >
            <AlertTitle>Registration Required</AlertTitle>
            It looks like you don't have an account with Vicunous yet. To join
            our community and access the app, please sign up for our{" "}
            {
              <a
                href="https://www.vicunous.com/#waitlist"
                target="_blank"
                rel="noreferrer"
              >
                waitlist
              </a>
            }
            . We'll notify you as soon as registration is available. Thanks for
            your interest in Vicunous!
          </Alert>
        )}
      </div>

      <div className="login__error">
        {showMicrosoftAlert && (
          <Alert
            severity="warning"
            open={showMicrosoftAlert}
            onClose={handleMicrosoftAlertClose}
          >
            <AlertTitle>Upcoming Feature</AlertTitle>
            We're working on adding "Login with Microsoft" soon! In the
            meantime, please use "Login with Google" to access your account.
            Thanks for your understanding!
          </Alert>
        )}
      </div>

      <div className="login__error">
        {showErrorLogin && (
          <Alert
            severity="warning"
            open={showErrorLogin}
            onClose={handleErrorLoginClose}
          >
            <AlertTitle>Login Issue</AlertTitle>
            Oops! It seems there was a problem logging you in. Please give it
            another try, and if the issue persists, feel free to contact our
            support team for assistance.
          </Alert>
        )}
      </div>
    </div>
  );
}

export default Login;
