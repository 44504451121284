import React from "react";
import "./Navbar.css";
import { useStateValue } from "../StateProvider";
import { Avatar } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

function Navbar() {
  const [{ user }] = useStateValue();

  return (
    <div className="navbar">
      <div className="navbar__left">
        <Avatar src="/purple_logo_1024x1024.png" alt="Vicunous logo" />
      </div>

      <div className="navbar__middle">
        <div className="navbar__option navbar__option--active">
          <HomeIcon fontSize="large" />
        </div>
      </div>

      <div className="navbar__right">
        <Avatar src={user.photoURL} alt="Profile photo" />
      </div>
    </div>
  );
}

export default Navbar;
