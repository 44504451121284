import React from "react";
import "./Feed.css";

function Feed() {
  return (
    <div className="feed">
      <h1>Coming soon ...</h1>
    </div>
  );
}

export default Feed;
