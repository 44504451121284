import React from "react";
import { useStateValue } from "../StateProvider";
import Login from "../components/Login";
import Navbar from "../components/Navbar";
import Feed from "../components/Feed";

function HomePage() {
  const [{ user }] = useStateValue();

  return (
    <div>
      {!user ? (
        <Login />
      ) : (
        <div className="homepage">
          <Navbar />
          <Feed />
        </div>
      )}
    </div>
  );
}

export default HomePage;
